import Button from "react-bootstrap/Button";
import Table from 'react-bootstrap/Table';
import { BookMetadata, GetAllBookMetadataResponse } from "bookapiinterface";
import React from "react";
import { useState } from "react";
import { UserManager } from "bookwebsite-usermanager";

interface AllMetadataProps {
    bookMetadatas: BookMetadata[];
    userManager: UserManager;
    setBookMetadataResponse: (response: GetAllBookMetadataResponse) => void;
}

interface MetadataProps {
    bookMetadata: BookMetadata;
    userManager: UserManager;
    setBookMetadataResponse: (response: GetAllBookMetadataResponse) => void;
}

interface ConfirmationPopupProps {
    isOpen: boolean;
    book: BookMetadata;
    onConfirm: () => void;
    onCancel: () => void;
}

const domainName = !window.location.origin.includes("dev") ? "lumoslogos.com" : "dev.lumoslogos.com"

// Confirmation pop-up component
function ConfirmationPopup({ isOpen, onConfirm, onCancel, book }: ConfirmationPopupProps) {
    if (!isOpen) return <></>;
    return (
      <div className="w-fit p-2 text-center absolute h-fit border-2 border-black rounded-md mx-auto left-0 right-0 top-10 z-50 bg-white flex-col flex">
        <p>{`Are you sure you want to delete ${book.title}?`}</p>
        <div className="flex flex-row justify-between">
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm}>Confirm</Button>
        </div>
      </div>
    );
  }


  

function BookMetadataView({bookMetadata, userManager, setBookMetadataResponse}: MetadataProps) {

    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    function deleteBook(bookId: string) {
        userManager.deleteBook(bookId).then((event) => {
          userManager.getAllBookMetadata().then((response) => {
            setBookMetadataResponse(response);
          });
        });
      }

    return (
        <tr>
                <td>
                    {bookMetadata.title}
                </td>
                <td>
                    {bookMetadata.author}
                </td>
                <td>
                    {bookMetadata.translator}
                </td>
                <td>
                    <a href={"https://edit." + domainName + "?book=" + bookMetadata.bookId}>
                        <Button>Edit Book</Button>
                    </a>
                </td>
                <td>
                    <Button onClick={() => {
                        //deleteBook(bookMetadata.bookId);
                        setIsConfirmOpen(true);
                    }}>Delete Book</Button>
                    <ConfirmationPopup isOpen={isConfirmOpen} book={bookMetadata} onConfirm={() => {
                        deleteBook(bookMetadata.bookId);
                        setIsConfirmOpen(false);
                    }} onCancel={() => {
                        setIsConfirmOpen(false);
                    }} />
                </td>
            </tr>
    )
}
export function BookEditDisplay({bookMetadatas, userManager, setBookMetadataResponse}: AllMetadataProps) {
    const jsxBookMetadataResponse: JSX.Element[] = bookMetadatas.map(
        (metadata, indx) => (
            <BookMetadataView key={`${indx}-bookmetadata`} bookMetadata={metadata} userManager={userManager} setBookMetadataResponse={setBookMetadataResponse}/>
        )
    );

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Author</th>
                    <th>Translator</th>
                    <th>Edit Book</th>
                    <th>Delete Book</th>
                </tr>
            </thead>
            <tbody>
                {jsxBookMetadataResponse}
            </tbody>
        </Table>
    );
}