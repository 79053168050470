import React from "react";
import { HTMLElement } from "node-html-parser";
export class MainMatter {
    copy() {
        return new MainMatter();
    }
    toLatex() {
        return "\\mainmatter\n";
    }
    toJSX() {
        return (React.createElement("h1", null, "MainMatter"));
    }
    toString() {
        return "<h1>MainMatter</h1>";
    }
    fromHTML(node) {
        return [new MainMatter()];
    }
    isHTML(node) {
        if (!(node instanceof HTMLElement))
            return false;
        if (!(node.rawTagName === "h1"))
            return false;
        if (!(node.innerText === "MainMatter"))
            return false;
        return true;
    }
    clean() {
        return [this.copy()];
    }
}
