import React from "react";
import { BookPartContainer } from "./BookPartContainer.js";
import { Paragraph, convertNodeList } from "../index.js";
import { HTMLElement } from "node-html-parser";
import { homeOrphanWords } from "../functions/homeOrphanWords.js";
export var FootnoteType;
(function (FootnoteType) {
    FootnoteType[FootnoteType["Inline"] = 0] = "Inline";
    FootnoteType[FootnoteType["EndOfSingleSpaceParagraph"] = 1] = "EndOfSingleSpaceParagraph";
    FootnoteType[FootnoteType["EndOfDoubleSpaceParagraph"] = 2] = "EndOfDoubleSpaceParagraph";
})(FootnoteType || (FootnoteType = {}));
export class Footnote extends BookPartContainer {
    constructor(children, footnoteType = FootnoteType.Inline) {
        super(children);
        this.footnoteType = footnoteType;
        this.xmlKeyword = "footnote";
    }
    copy() {
        return new Footnote(this.getChildren(), this.footnoteType);
    }
    toLatex() {
        switch (this.footnoteType) {
            case FootnoteType.Inline:
                return `\\footnote{${this.getChildren().map((child) => child.toLatex()).join("\n")}} `;
            case FootnoteType.EndOfSingleSpaceParagraph:
                return `\\footnote{${this.getChildren().map((child) => child.toLatex()).join("\n")}}\n\\\\\n`;
            case FootnoteType.EndOfDoubleSpaceParagraph:
                return `\\footnote{${this.getChildren().map((child) => child.toLatex()).join("\n")}}\n\n\n`;
        }
    }
    toJSX() {
        switch (this.footnoteType) {
            case FootnoteType.Inline:
                return React.createElement("blockquote", { id: Footnote.FOOTNOTE_ATTRIBUTE, itemType: "0" }, this.getChildren().map((child, indx) => { return React.cloneElement(child.toJSX(), { key: indx.toString() }); }));
            case FootnoteType.EndOfSingleSpaceParagraph:
                return React.createElement(React.Fragment, null,
                    React.createElement("blockquote", { id: Footnote.FOOTNOTE_ATTRIBUTE, itemType: "1" }, this.getChildren().map((child, indx) => { return React.cloneElement(child.toJSX(), { key: indx.toString() }); })),
                    React.createElement("p", null));
            case FootnoteType.EndOfDoubleSpaceParagraph:
                return React.createElement(React.Fragment, null,
                    React.createElement("blockquote", { id: Footnote.FOOTNOTE_ATTRIBUTE, itemType: "1" }, this.getChildren().map((child, indx) => { return React.cloneElement(child.toJSX(), { key: indx.toString() }); })),
                    React.createElement("p", null),
                    React.createElement("p", null));
        }
    }
    toString() {
        switch (this.footnoteType) {
            case FootnoteType.Inline:
                return `<blockquote id="${Footnote.FOOTNOTE_ATTRIBUTE}">${this.getChildren().map((child) => child.toString()).join("")}</blockquote>`;
            case FootnoteType.EndOfSingleSpaceParagraph:
                return `<blockquote id="${Footnote.FOOTNOTE_ATTRIBUTE}">${this.getChildren().map((child) => child.toString()).join("")}</blockquote><p></p>`;
            case FootnoteType.EndOfDoubleSpaceParagraph:
                return `<blockquote id="${Footnote.FOOTNOTE_ATTRIBUTE}">${this.getChildren().map((child) => child.toString()).join("")}</blockquote><p></p><p></p>`;
        }
    }
    fromHTML(node) {
        if (!(node instanceof HTMLElement))
            throw new Error("Text node in the footnote parser");
        const nextElement = node.nextElementSibling;
        if (nextElement !== null && new Paragraph([]).isHTML(nextElement) && nextElement.rawText.length === 0) {
            const nextElementNext = nextElement.nextElementSibling;
            if (nextElementNext !== null && new Paragraph([]).isHTML(nextElementNext) && nextElementNext.rawText.length === 0) {
                return [new Footnote(convertNodeList(node.childNodes), FootnoteType.EndOfDoubleSpaceParagraph)];
            }
            return [new Footnote(convertNodeList(node.childNodes), FootnoteType.EndOfSingleSpaceParagraph)];
        }
        if (nextElement !== null && nextElement.rawTagName.startsWith("h")) {
            return [new Footnote(convertNodeList(node.childNodes), FootnoteType.EndOfSingleSpaceParagraph)];
        }
        return [new Footnote(convertNodeList(node.childNodes), FootnoteType.Inline)];
    }
    isHTML(node) {
        return Footnote.isHTML(node);
    }
    static isHTML(node) {
        if (node === undefined || node === null)
            return false;
        if (!(node instanceof HTMLElement))
            return false;
        if (!(node.hasAttribute("id") && node.getAttribute("id") === Footnote.FOOTNOTE_ATTRIBUTE))
            return false;
        return node.rawTagName === "blockquote";
    }
    clean() {
        const children = this.children.map((child) => child.copy());
        if (children.length === 0)
            return [];
        const newChildren = homeOrphanWords(children).flatMap((child) => child.clean());
        if (newChildren.length === 0)
            return [];
        const copy = this.copy();
        copy.setChildren(newChildren);
        return [copy];
    }
}
Footnote.FOOTNOTE_ATTRIBUTE = "data_node_footnote";
Footnote.REMIRROR_STYLE_INLINE = {
    "border-width": "2px",
    "border-color": "rgb(0 0 0)",
    "border-radius": "0.375rem",
    "font-style": "normal",
    "border-top": "none",
    "border-bottom": "none"
};
Footnote.REMIRROR_STYLE_NON_INLINE = {
    "border-width": "2px",
    "border-color": "rgb(0 0 0)",
    "border-radius": "0.375rem",
    "font-style": "normal",
    "border-top": "none"
};
