import { HTMLElement } from "node-html-parser";
import { BookPartContainer } from "./BookPartContainer.js";
import { Words } from "../Words.js";
import { Quote, convertNodeList } from "../index.js";
import React from "react";
import { Footnote, FootnoteType } from "./Footnote.js";
export class Paragraph extends BookPartContainer {
    constructor(children, singleSpace = false, indent = false, nextElementIsInline = false) {
        super(children);
        this.singleSpace = singleSpace;
        this.indent = indent;
        this.nextElementIsInline = nextElementIsInline;
        this.xmlKeyword = "paragraph";
    }
    copy() {
        return new Paragraph(this.getChildren(), this.singleSpace, this.indent, this.nextElementIsInline);
    }
    toLatex() {
        const childrenLatex = this.getChildren().map((child) => child.toLatex()).join("");
        if (this.nextElementIsInline)
            return childrenLatex;
        if (this.singleSpace)
            return childrenLatex + "\n\\\\\n";
        return childrenLatex + "\n\n\n";
    }
    toJSX() {
        const paragraph = React.createElement("p", { id: (this.indent) ? Paragraph.INDENT_ATTRIBUTE : "" }, this.getChildren().map((child, indx) => { return React.cloneElement(child.toJSX(), { key: indx.toString() }); }));
        if (this.singleSpace || this.nextElementIsInline) {
            return paragraph;
        }
        return (React.createElement(React.Fragment, null,
            paragraph,
            React.createElement("p", null)));
    }
    toString() {
        const paragraph = `<p>${this.getChildren().map((child) => { return child.toString(); }).join("")}</p>`;
        if (this.singleSpace || this.nextElementIsInline) {
            return paragraph;
        }
        return `${paragraph}<p></p>`;
    }
    fromHTML(node) {
        if (!(node instanceof HTMLElement))
            throw new Error("Text node in the paragraph parser");
        const children = convertNodeList(node.childNodes);
        if (children.length === 0)
            return [];
        const nextElement = node.nextElementSibling;
        let singleSpace = (nextElement === null) || Paragraph.isNonEmptyParagraph(nextElement) || Quote.isHTML(nextElement);
        let nextElementIsInline = Footnote.isHTML(nextElement);
        if (Paragraph.isEmptyParagraph(nextElement)) {
            nextElementIsInline = Footnote.isHTML(nextElement?.nextElementSibling);
        }
        // If is end of footnote, don't add spaces in latex
        if ((Paragraph.insideFootnote(node) || Paragraph.insideQuote(node)) && (nextElement === null || nextElement === undefined)) {
            nextElementIsInline = true;
        }
        // set single space to true if next element is inline
        singleSpace = singleSpace || nextElementIsInline;
        const previousElement = node.previousElementSibling;
        const previousElementPrevious = previousElement?.previousElementSibling;
        // No indent when start of quote or footnote (i.e. prev element is null)
        // or when first paragraph after title
        // or when previous paragraph is single space
        // no indent if there is no previous content
        const noIndent = previousElement === null ||
            // no indent when after chapter start
            previousElement.rawTagName.startsWith("h") ||
            // no indent when previous paragraph is single space
            Paragraph.isNonEmptyParagraph(previousElement) ||
            // no indent when previous element is inline footnote
            Footnote.isHTML(previousElement) ||
            // no indent when previous element is footnote end of single-space
            (Paragraph.isEmptyParagraph(previousElement) && Footnote.isHTML(previousElementPrevious)) ||
            // no indent when previous element is quote
            Quote.isHTML(previousElement) ||
            // no indent when inside a quotation
            Paragraph.insideQuote(node);
        return [new Paragraph(children, singleSpace, !noIndent, nextElementIsInline)];
    }
    static isEmptyParagraph(element) {
        return (element !== null && Paragraph.isHTML(element) && element.rawText.length === 0);
    }
    static isNonEmptyParagraph(element) {
        return (element !== null && Paragraph.isHTML(element) && element.rawText.length > 0);
    }
    static insideQuote(element) {
        const parent = element.parentNode;
        if (parent === null || parent === undefined)
            return false;
        if (Footnote.isHTML(parent))
            return false;
        if (Quote.isHTML(parent))
            return true;
        return this.insideQuote(parent);
    }
    static insideFootnote(element) {
        const parent = element.parentNode;
        if (parent === null || parent === undefined)
            return false;
        if (Quote.isHTML(parent))
            return false;
        if (Footnote.isHTML(parent))
            return true;
        return this.insideFootnote(parent);
    }
    static isHTML(node) {
        if (!(node instanceof HTMLElement))
            return false;
        return node.rawTagName === "p";
    }
    isHTML(node) {
        return Paragraph.isHTML(node);
    }
    static condenseWords(words) {
        if (words.length === 0)
            return [];
        const newChildren = [];
        words.reduce((acc, child) => {
            if (child instanceof Words) {
                const prevChild = acc[acc.length - 1];
                if (prevChild !== undefined && prevChild instanceof Words && prevChild.bold === child.bold && prevChild.italics === child.italics) {
                    const newWord = new Words(prevChild.text + child.text, prevChild.italics, prevChild.bold);
                    acc.pop();
                    acc.push(...newWord.clean());
                }
                else {
                    acc.push(...child.clean());
                }
            }
            else {
                throw new Error("Paragraph contains non-words");
            }
            return acc;
        }, newChildren);
        return newChildren;
    }
    static isEmpty(children) {
        return children.every((child) => child instanceof Words && child.text.replaceAll(" ", "").replaceAll("\n", "").replaceAll("\t", "").replaceAll("\r", "").length === 0);
    }
    clean() {
        let children = this.children.map((child) => child.copy());
        if (children.length === 0)
            return [];
        const firstWord = children[0];
        if (firstWord instanceof Words) {
            children[0] = new Words(firstWord.text.trimStart(), firstWord.italics, firstWord.bold);
        }
        const lastWord = children[children.length - 1];
        if (lastWord instanceof Words) {
            children[children.length - 1] = new Words(lastWord.text.trimEnd(), lastWord.italics, lastWord.bold);
        }
        // If all children are words, condense words
        if (children.every((child) => child instanceof Words)) {
            children = Paragraph.condenseWords(children);
            if (Paragraph.isEmpty(children))
                return [];
            return [new Paragraph(children, this.singleSpace, this.indent, this.nextElementIsInline)];
        }
        // If there are non-words, separate out from under paragraph
        const onlyWordChildren = [];
        let currentSection = [];
        for (let ii = 0; ii < children.length; ii++) {
            if (children[ii] instanceof Words) {
                currentSection.push(...children[ii].clean());
            }
            else {
                if (currentSection.length > 0) {
                    onlyWordChildren.push(new Paragraph(currentSection));
                }
                onlyWordChildren.push(children[ii]);
                currentSection = [];
            }
        }
        if (currentSection.length > 0) {
            onlyWordChildren.push(new Paragraph(currentSection));
        }
        // Give first paragraph same indent property and last paragraph same spacing property
        let firstParagraphIndex = -1;
        let lastParagraphIndex = -1;
        for (let ii = 0; ii < onlyWordChildren.length; ii++) {
            if (onlyWordChildren[ii] instanceof Paragraph) {
                if (firstParagraphIndex === -1)
                    firstParagraphIndex = ii;
                lastParagraphIndex = ii;
            }
        }
        if (firstParagraphIndex !== -1) {
            const firstParagraph = onlyWordChildren[firstParagraphIndex];
            if (!(firstParagraph instanceof Paragraph))
                throw new Error("First paragraph in paragraph is not Paragraph");
            onlyWordChildren[firstParagraphIndex] = new Paragraph(firstParagraph.getChildren(), firstParagraph.singleSpace, this.indent, firstParagraph.nextElementIsInline);
        }
        if (lastParagraphIndex !== -1) {
            const lastParagraph = onlyWordChildren[lastParagraphIndex];
            if (!(lastParagraph instanceof Paragraph))
                throw new Error("Last paragraph in paragraph is not Paragraph");
            onlyWordChildren[lastParagraphIndex] = new Paragraph(lastParagraph.getChildren(), this.singleSpace, lastParagraph.indent, lastParagraph.nextElementIsInline);
        }
        const lastElement = onlyWordChildren[onlyWordChildren.length - 1];
        if (lastElement instanceof Footnote) {
            const footnoteType = this.singleSpace ? FootnoteType.EndOfSingleSpaceParagraph : FootnoteType.EndOfDoubleSpaceParagraph;
            onlyWordChildren[onlyWordChildren.length - 1] = new Footnote(lastElement.getChildren(), footnoteType);
        }
        return onlyWordChildren.flatMap((child) => child.clean());
    }
}
Paragraph.INDENT_ATTRIBUTE = "data_node_text_indent";
Paragraph.INDENT_STYLES = {
    "text-indent": "2rem"
};
