import axios from 'axios';
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retryDelay: axiosRetry.exponentialDelay, retries: 5 });
export class ApiManager {
    constructor(origin, serverSide = false) {
        this.origin = origin;
        this.serverSide = serverSide;
        this.contentType = "'application/x-www-form-urlencoded'";
        this.responseType = "code";
        this.accessToken = "";
        this.idToken = "";
        if (!(this.origin.includes("dev") || this.origin.includes("localhost"))) {
            this.bookApi = "https://api.lumoslogos.com";
            this.clientId = "m4tih6lo5d2asausttc6d7bcg";
            this.authUrl = "https://auth.lumoslogos.com";
        }
        else {
            this.bookApi = "https://api.dev.lumoslogos.com";
            this.clientId = "3gq9l1dbt4fmsr1cttf9jkbm4d";
            this.authUrl = "https://auth.dev.lumoslogos.com";
        }
    }
    setAccessToken(accessToken) {
        this.accessToken = accessToken;
    }
    setIdToken(idToken) {
        this.idToken = idToken;
    }
    sendAuthCode(code) {
        const data = {
            grant_type: "authorization_code",
            client_id: this.clientId,
            code: code,
            redirect_uri: this.origin
        };
        const options = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };
        const authorization = `Basic ${btoa(code)}`;
        const tokenUrl = `${this.authUrl}/oauth2/token?Content-Type=${this.contentType}&Authorization=${authorization}`;
        console.log("Sending request to %s with data %s", tokenUrl, data);
        return axios.post(tokenUrl, data, options);
    }
    sendRefreshToken(refreshToken) {
        const data = {
            grant_type: "refresh_token",
            client_id: this.clientId,
            refresh_token: refreshToken,
            redirect_uri: this.origin
        };
        const options = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };
        const tokenUrl = `${this.authUrl}/oauth2/token?Content-Type=${this.contentType}`;
        console.log("Sending request to %s with data %s", tokenUrl, data);
        return axios.post(tokenUrl, data, options);
    }
    getLoginUrl() {
        return `${this.authUrl}/login?client_id=${this.clientId}&redirect_uri=${this.origin}&response_type=${this.responseType}`;
    }
    getToC(bookId) {
        const url = this.bookApi + "/getToC/" + bookId;
        return this.getApiRequest(url);
    }
    putLogChanges(bookId, requestBody) {
        const url = this.bookApi + "/putDiff/" + bookId;
        return this.postApiRequest(url, requestBody);
    }
    getBookContent(bookId, startId, endId, config) {
        let url = this.bookApi + "/getBookContent/" + bookId + "/" + startId.toString() + "/" + endId.toString();
        if (config === null || Object.keys(config).length === 0)
            return this.getApiRequest(url);
        url = url + "?";
        for (const [key, value] of Object.entries(config)) {
            url += key + "=" + value + "&";
        }
        return this.getApiRequest(url);
    }
    getBookMetadata(bookId) {
        const url = this.bookApi + "/getBookMetadata/" + bookId;
        return this.getApiRequest(url);
    }
    getAllBookMetadata() {
        const url = this.bookApi + "/getAllBookMetadata";
        return this.getApiRequest(url);
    }
    createBookPdf(bookId, request) {
        const url = this.bookApi + "/createBookPdf/" + bookId;
        return this.postApiRequest(url, request);
    }
    getBookPdfUrl(bookId, token) {
        const url = this.bookApi + "/getBookPdfUrl/" + bookId + "/" + token;
        return this.getApiRequest(url);
    }
    putNewBook(requestBody) {
        const url = this.bookApi + "/putNewBook";
        return this.postApiRequest(url, requestBody);
    }
    getAllDiffs(bookId) {
        const url = this.bookApi + "/getAllDiffs/" + bookId;
        return this.postApiRequest(url, {});
    }
    getBookDiff(bookId, start, end) {
        const url = this.bookApi + "/getBookDiff/" + bookId + "/" + start.toString() + "/" + end.toString();
        return this.getApiRequest(url);
    }
    deleteBook(bookId) {
        const url = this.bookApi + "/deleteBook/" + bookId;
        return this.getApiRequest(url);
    }
    getApiRequest(url) {
        let headers = {
            "Authorization": `Bearer ${this.idToken}`,
            "accesstoken": this.accessToken
        };
        if (this.serverSide)
            headers = { ...headers, origin: this.origin };
        return axios.get(url, { headers });
    }
    postApiRequest(url, data) {
        let headers = {
            "Authorization": `Bearer ${this.idToken}`,
            "accesstoken": this.accessToken
        };
        if (this.serverSide)
            headers = { ...headers, origin: this.origin };
        return axios.post(url, data, { headers });
    }
}
