import { DiffChange } from "./LogChanges.js";
export class StoredDiff {
    constructor(diffChange, time) {
        this.diffChange = diffChange;
        this.time = time;
    }
    getTime() {
        return this.time;
    }
    getDiff() {
        return this.diffChange;
    }
    toJSON() {
        return {
            diff: this.diffChange.toJSON(),
            time: this.time,
        };
    }
    static fromJSON(json) {
        return new StoredDiff(DiffChange.fromJSON(json.diff), json.time);
    }
}
