import { DiffChange, TableOfContents, fromHtmlString } from "bookdata";
import { BookDiff } from "bookdiff";
;
;
;
;
;
;
export function reviver(key, value) {
    if (value.type === "wrapper") {
        return fromHtmlString(value.html);
    }
    if (value.type === "tableOfContents") {
        return TableOfContents.fromJSON(value);
    }
    if (value.type === "diffChange") {
        return DiffChange.fromJSON(value);
    }
    if (value.type === "bookDiff") {
        return BookDiff.fromJSON(value);
    }
    return value;
}
