export var MessageType;
(function (MessageType) {
    MessageType[MessageType["Error"] = 0] = "Error";
    MessageType[MessageType["Success"] = 1] = "Success";
    MessageType[MessageType["Loading"] = 2] = "Loading";
})(MessageType || (MessageType = {}));
export const defaultMessageHandler = {
    createMessage(message, messageType) {
        return "";
    },
    deleteMessage(id) {
        return;
    },
    updateMessage(id, message, messageType) {
        return;
    }
};
