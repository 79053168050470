import { v4 as uuidv4 } from "uuid";
import { defaultMessageHandler, MessageType } from "./MessageHandler.js";
export class RequestExecutor {
    constructor() {
        this.queue = [];
        this.putLock = false;
        this.putsInQueue = 0;
        this.messageHandler = defaultMessageHandler;
    }
    setMessageHandler(messageHandler) {
        this.messageHandler = messageHandler;
    }
    async execute(requestFunction, isPut) {
        const needToWait = this.putLock || this.queue.length > 0;
        if (isPut) {
            let id = "";
            if (needToWait) {
                id = uuidv4();
                this.queue.push(id);
                this.putsInQueue += 1;
                while (this.queue[0] !== id) {
                    await new Promise(resolve => setTimeout(resolve, 200));
                }
            }
            this.putLock = true;
            const messageId = this.messageHandler.createMessage("Saving data", MessageType.Loading);
            let retryCount = 0;
            while (true) {
                try {
                    const returnVal = await requestFunction();
                    if (this.queue.length > 0 && this.queue[0] === id) {
                        this.queue.shift();
                        this.putsInQueue -= 1;
                    }
                    this.putLock = false;
                    this.messageHandler.updateMessage(messageId, "Data saved", MessageType.Success);
                    return returnVal;
                }
                catch (e) {
                    retryCount = retryCount + 1;
                    this.messageHandler.updateMessage(messageId, `Error saving data. Retry ${retryCount}`, MessageType.Error);
                    console.error(e);
                }
            }
        }
        if (needToWait) {
            const id = uuidv4();
            this.queue.push(id);
            while (this.queue[0] !== id) {
                await new Promise(resolve => setTimeout(resolve, 200));
            }
            this.queue.shift();
        }
        return await requestFunction();
    }
    async awaitPutsInQueue() {
        while (this.putsInQueue > 0 || this.putLock) {
            await new Promise(resolve => setTimeout(resolve, 200));
        }
    }
}
