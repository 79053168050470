import React from "react";
import { HTMLElement, TextNode } from "node-html-parser";
import { formatTextForString, decodeTextFromString, convertNodeList, formatTextForLatex } from "./index.js";
const defaultFootnoteIndicator = {
    superscript: false,
    hyperlink: false,
    href: "",
    id: ""
};
export class Words {
    constructor(text, italics = false, bold = false, footnoteIndicator = defaultFootnoteIndicator) {
        this.xmlKeyWord = "words";
        this.text = text;
        this.italics = italics;
        this.bold = bold;
        this.footnoteIndicator = footnoteIndicator;
    }
    toLatex() {
        let word = formatTextForLatex(this.text);
        if (this.italics)
            word = `\\textit{${word}}`;
        if (this.bold)
            word = `\\textbf{${word}}`;
        return word;
    }
    copy() {
        return new Words(this.text, this.italics, this.bold, this.footnoteIndicator);
    }
    toJSX() {
        let words = React.createElement(React.Fragment, null, this.text);
        if (this.italics) {
            words = React.createElement("i", null, words);
        }
        if (this.bold) {
            words = React.createElement("b", null, words);
        }
        if (this.footnoteIndicator.hyperlink) {
            words = React.createElement("a", { href: this.footnoteIndicator.href, id: this.footnoteIndicator.id }, words);
        }
        if (this.footnoteIndicator.superscript) {
            words = React.createElement("sup", null, words);
        }
        return React.createElement("span", null, words);
    }
    toString() {
        let words = `${formatTextForString(this.text)}`;
        if (this.italics) {
            words = `<i>${words}</i>`;
        }
        if (this.bold) {
            words = `<b>${words}</b>`;
        }
        return `<span>${words}</span>`;
    }
    isHTML(node) {
        if (node instanceof TextNode)
            return true;
        if (!(node instanceof HTMLElement))
            return false;
        if (node.rawTagName === "i" || node.rawTagName === "b" || node.rawTagName === "span" || node.rawTagName === "em" || node.rawTagName === "strong")
            return true;
        return false;
    }
    fromHTML(node) {
        if (node instanceof HTMLElement)
            return convertNodeList(node.childNodes);
        let isBold = false;
        let isItalics = false;
        for (let parentNode = node; parentNode = parentNode.parentNode; parentNode != null) {
            if (!(parentNode instanceof HTMLElement))
                continue;
            if (parentNode.rawTagName === "i" || parentNode.rawTagName === "em")
                isItalics = true;
            if (parentNode.rawTagName === "b" || parentNode.rawTagName === "strong")
                isBold = true;
        }
        return [new Words(decodeTextFromString(node.text), isItalics, isBold)];
    }
    clean() {
        if (this.text.length === 0)
            return [];
        let newText = this.text;
        newText = newText.replace(/ +/g, ' ');
        return [new Words(newText, this.italics, this.bold)];
    }
}
