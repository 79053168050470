import { fromHtmlString } from "../index.js";
import { BookPartTitle } from "../titles/BookPartTitle.js";
import { Wrapper } from "../Wrapper.js";
export class TableOfContents {
    constructor() {
        this.titleList = [];
    }
    addContent(bookTitle, partId) {
        this.titleList.push([bookTitle, partId]);
    }
    getIndx(title) {
        for (let ii = 0; ii < this.titleList.length; ii++) {
            if (title[0].toString() === this.titleList[ii][0].toString() &&
                title[1] === this.titleList[ii][1]) {
                return ii;
            }
        }
        return 0;
    }
    // Given title at indx, return list of titles which are its direct children
    *getChildren(title) {
        const indx = this.getIndx(title);
        const level = this.titleList[indx][0].getLevel();
        let smallestChildLevel = 1000000;
        for (let ii = indx + 1; ii < this.titleList.length; ii++) {
            const title = this.titleList[ii][0];
            if (title.getLevel() <= level)
                return;
            if (title.getLevel() < smallestChildLevel)
                smallestChildLevel = title.getLevel();
            if (title.getLevel() === smallestChildLevel)
                yield this.titleList[ii];
        }
    }
    *getTopChildren() {
        let smallestChildLevel = 1000000;
        for (let ii = 0; ii < this.titleList.length; ii++) {
            const title = this.titleList[ii][0];
            if (title.getLevel() < smallestChildLevel)
                smallestChildLevel = title.getLevel();
            if (title.getLevel() === smallestChildLevel)
                yield this.titleList[ii];
        }
    }
    getTableOfContents(leafLevel) {
        const newTableOfContents = new TableOfContents();
        for (const [content, id] of this.titleList) {
            if (content.getLevel() <= leafLevel.getLevel()) {
                newTableOfContents.addContent(content, id);
            }
        }
        return newTableOfContents;
    }
    *getTitles() {
        for (const title of this.titleList) {
            yield title;
        }
    }
    toJSON() {
        return {
            content: new Wrapper(this.titleList.map((title) => { return title[0]; })).toString(),
            ids: JSON.stringify(this.titleList.map((title) => { return title[1]; })),
            type: "tableOfContents"
        };
    }
    static fromJSON(value) {
        const tableOfContents = new TableOfContents();
        const book = fromHtmlString(value.content);
        const ids = JSON.parse(value.ids);
        const children = book.getChildren();
        for (let ii = 0; ii < ids.length; ii++) {
            const child = children[ii];
            if (!(child instanceof BookPartTitle))
                throw new Error("Non title found in table of contents");
            tableOfContents.addContent(child, ids[ii]);
        }
        return tableOfContents;
    }
}
