import { BookPartTitle, Chapter, Footnote, FootnoteType, FrontMatter, MainMatter, Paragraph, Part, Quote, Section, Words } from "bookdata";
import React from "react";
import { diffWords } from "diff";
import { BookPartContainer } from "bookdata";
import { calculateMapping } from "./calculate.js";
import { BOOK_PART_ADDED, BOOK_PART_DELETED, DOUBLE_SPACE_ADDED, DOUBLE_SPACE_REMOVED, FULL_BORDER_ADDED, FULL_BORDER_REMOVED, INDENT_ADDED, INDENT_REMOVED, WORD_DIFF_ADDED, WORD_DIFF_REMOVED, WORD_STYLE_ADDED, WORD_STYLE_REMOVED } from "./Constants.js";
export function getDiff(oldPart, newPart) {
    oldPart = convertMatter(oldPart);
    newPart = convertMatter(newPart);
    if (oldPart instanceof BookPartTitle && newPart instanceof BookPartTitle) {
        return handleTwoTitles(oldPart, newPart);
    }
    else if (oldPart instanceof BookPartContainer && newPart instanceof BookPartContainer) {
        return handleTwoContainers(oldPart, newPart);
    }
    else if (oldPart instanceof Words && newPart instanceof Words) {
        return handleTwoWords(oldPart, newPart);
    }
    else if (oldPart instanceof BookPartTitle && newPart instanceof BookPartContainer) {
        return handleTitleAndContainer(oldPart, newPart);
    }
    else if (oldPart instanceof BookPartContainer && newPart instanceof BookPartTitle) {
        return handleContainerAndTitle(oldPart, newPart);
    }
    console.log("Old part");
    console.log(oldPart);
    console.log("New part");
    console.log(newPart);
    throw new Error("Not implemented");
}
function convertMatter(part) {
    if (part instanceof MainMatter) {
        return new Part("MainMatter");
    }
    else if (part instanceof FrontMatter) {
        return new Part("FrontMatter");
    }
    else {
        return part;
    }
}
function getTextDiff(oldStr, newStr) {
    const changes = diffWords(oldStr, newStr, { ignoreWhitespace: false });
    const oldElements = [];
    const newElements = [];
    for (const change of changes) {
        const value = change.value;
        if (change.added) {
            newElements.push(React.createElement("span", { className: WORD_DIFF_ADDED }, value));
        }
        else if (change.removed) {
            oldElements.push(React.createElement("span", { className: WORD_DIFF_REMOVED }, value));
        }
        else {
            oldElements.push(React.createElement("span", null, value));
            newElements.push(React.createElement("span", null, value));
        }
    }
    return [(React.createElement(React.Fragment, null, oldElements)), (React.createElement(React.Fragment, null, newElements))];
}
function handleTwoTitles(oldTitle, newTitle) {
    let [oldContent, newContent] = getTextDiff(oldTitle.title, newTitle.title);
    if (oldTitle.getLevel() !== newTitle.getLevel()) {
        oldContent = (React.createElement("span", { className: BOOK_PART_DELETED }, oldContent));
        newContent = (React.createElement("span", { className: BOOK_PART_ADDED }, newContent));
    }
    return [getTitleElement(oldContent, oldTitle), getTitleElement(newContent, newTitle)];
}
function handleTwoContainers(oldContainer, newContainer) {
    if (oldContainer instanceof Paragraph && newContainer instanceof Paragraph) {
        return handleTwoParagraphs(oldContainer, newContainer);
    }
    else if (oldContainer instanceof Footnote && newContainer instanceof Paragraph) {
        return handleFootnoteAndParagraph(oldContainer, newContainer);
    }
    else if (oldContainer instanceof Paragraph && newContainer instanceof Footnote) {
        return handleParagraphAndFootnote(oldContainer, newContainer);
    }
    else if (oldContainer instanceof Footnote && newContainer instanceof Footnote) {
        return handleTwoFootnotes(oldContainer, newContainer);
    }
    else if (oldContainer instanceof Quote && newContainer instanceof Quote) {
        return handleTwoQuotes(oldContainer, newContainer);
    }
    else if (oldContainer instanceof Quote && newContainer instanceof Footnote) {
        return handleQuoteAndFootnote(oldContainer, newContainer);
    }
    else if (oldContainer instanceof Footnote && newContainer instanceof Quote) {
        return handleFootnoteAndQuote(oldContainer, newContainer);
    }
    else if (oldContainer instanceof Paragraph && newContainer instanceof Quote) {
        return handleParagraphAndQuote(oldContainer, newContainer);
    }
    else if (oldContainer instanceof Quote && newContainer instanceof Paragraph) {
        return handleQuoteAndParagraph(oldContainer, newContainer);
    }
    throw new Error("Not implemented");
}
function handleFootnoteAndParagraph(oldContainer, newContainer) {
    const bookDiffs = calculateMapping(oldContainer.getChildren(), [newContainer]);
    const oldContent = [];
    const newContent = [];
    for (const bookDiff of bookDiffs) {
        const [oldPart, newPart] = bookDiff.getJsxPair();
        oldContent.push(oldPart);
        newContent.push(newPart);
    }
    return [getFootnoteElement(oldContainer, oldContent, FULL_BORDER_REMOVED), React.createElement(React.Fragment, null, newContent)];
}
function handleParagraphAndFootnote(oldContainer, newContainer) {
    const bookDiffs = calculateMapping([oldContainer], newContainer.getChildren());
    const oldContent = [];
    const newContent = [];
    for (const bookDiff of bookDiffs) {
        const [oldPart, newPart] = bookDiff.getJsxPair();
        oldContent.push(oldPart);
        newContent.push(newPart);
    }
    return [(React.createElement(React.Fragment, null, oldContent)), getFootnoteElement(newContainer, newContent, FULL_BORDER_ADDED)];
}
function handleTitleAndContainer(oldTitle, newContainer) {
    return [React.createElement("span", { className: BOOK_PART_DELETED }, oldTitle.toJSX()), React.createElement("span", { className: BOOK_PART_ADDED }, newContainer.toJSX())];
}
function handleContainerAndTitle(oldContainer, newTitle) {
    return [React.createElement("span", { className: BOOK_PART_DELETED }, oldContainer.toJSX()), React.createElement("span", { className: BOOK_PART_ADDED }, newTitle.toJSX())];
}
function handleQuoteAndFootnote(oldContainer, newContainer) {
    const bookDiffs = calculateMapping(oldContainer.getChildren(), newContainer.getChildren());
    const oldContent = [];
    const newContent = [];
    for (const bookDiff of bookDiffs) {
        const [oldPart, newPart] = bookDiff.getJsxPair();
        oldContent.push(oldPart);
        newContent.push(newPart);
    }
    return [getQuoteElement(oldContainer, oldContent, FULL_BORDER_REMOVED), getFootnoteElement(newContainer, newContent, FULL_BORDER_ADDED)];
}
function handleFootnoteAndQuote(oldContainer, newContainer) {
    const bookDiffs = calculateMapping(oldContainer.getChildren(), newContainer.getChildren());
    const oldContent = [];
    const newContent = [];
    for (const bookDiff of bookDiffs) {
        const [oldPart, newPart] = bookDiff.getJsxPair();
        oldContent.push(oldPart);
        newContent.push(newPart);
    }
    return [getFootnoteElement(oldContainer, oldContent, FULL_BORDER_REMOVED), getQuoteElement(newContainer, newContent, FULL_BORDER_ADDED)];
}
function handleParagraphAndQuote(oldContainer, newContainer) {
    const bookDiffs = calculateMapping([oldContainer], newContainer.getChildren());
    const oldContent = [];
    const newContent = [];
    for (const bookDiff of bookDiffs) {
        const [oldPart, newPart] = bookDiff.getJsxPair();
        oldContent.push(oldPart);
        newContent.push(newPart);
    }
    return [(React.createElement(React.Fragment, null, oldContent)), getQuoteElement(newContainer, newContent, FULL_BORDER_ADDED)];
}
function handleQuoteAndParagraph(oldContainer, newContainer) {
    const bookDiffs = calculateMapping(oldContainer.getChildren(), [newContainer]);
    const oldContent = [];
    const newContent = [];
    for (const bookDiff of bookDiffs) {
        const [oldPart, newPart] = bookDiff.getJsxPair();
        oldContent.push(oldPart);
        newContent.push(newPart);
    }
    return [getQuoteElement(oldContainer, oldContent, FULL_BORDER_REMOVED), (React.createElement(React.Fragment, null, newContent))];
}
function handleTwoQuotes(oldQuote, newQuote) {
    const bookDiffs = calculateMapping(oldQuote.getChildren(), newQuote.getChildren());
    const oldContent = [];
    const newContent = [];
    for (const bookDiff of bookDiffs) {
        const [oldPart, newPart] = bookDiff.getJsxPair();
        oldContent.push(oldPart);
        newContent.push(newPart);
    }
    return [getQuoteElement(oldQuote, oldContent, ""), getQuoteElement(newQuote, newContent, "")];
}
function getQuoteElement(quote, content, mainClassName) {
    return React.createElement("blockquote", { id: Quote.QUOTE_ATTRIBUTE, className: mainClassName }, content);
}
function handleTwoFootnotes(oldContainer, newContainer) {
    const bookDiffs = calculateMapping(oldContainer.getChildren(), newContainer.getChildren());
    const oldContent = [];
    const newContent = [];
    for (const bookDiff of bookDiffs) {
        const [oldPart, newPart] = bookDiff.getJsxPair();
        oldContent.push(oldPart);
        newContent.push(newPart);
    }
    let oldExtraClassName = "";
    let newExtraClassName = "";
    let oldExtraExtraClassName = "";
    let newExtraExtraClassName = "";
    if (oldContainer.footnoteType !== newContainer.footnoteType) {
        if (oldContainer.footnoteType === FootnoteType.Inline) {
            newExtraClassName += DOUBLE_SPACE_ADDED;
            if (newContainer.footnoteType === FootnoteType.EndOfDoubleSpaceParagraph) {
                newExtraExtraClassName += DOUBLE_SPACE_ADDED;
            }
        }
        else if (oldContainer.footnoteType === FootnoteType.EndOfSingleSpaceParagraph) {
            if (newContainer.footnoteType === FootnoteType.Inline) {
                oldExtraClassName += DOUBLE_SPACE_REMOVED;
            }
            else {
                newExtraExtraClassName += DOUBLE_SPACE_ADDED;
            }
        }
        else {
            oldExtraExtraClassName += DOUBLE_SPACE_REMOVED;
            if (newContainer.footnoteType === FootnoteType.Inline) {
                oldExtraClassName += DOUBLE_SPACE_REMOVED;
            }
        }
    }
    return [getFootnoteElement(oldContainer, oldContent, "", oldExtraClassName, oldExtraExtraClassName),
        getFootnoteElement(newContainer, newContent, "", newExtraClassName, newExtraExtraClassName)];
}
function handleTwoParagraphs(oldParagraph, newParagraph) {
    const bookDiffs = calculateMapping(oldParagraph.getChildren(), newParagraph.getChildren());
    const oldContent = [];
    const newContent = [];
    for (const bookDiff of bookDiffs) {
        const [oldPart, newPart] = bookDiff.getJsxPair();
        oldContent.push(oldPart);
        newContent.push(newPart);
    }
    let oldClassName = "";
    let newClassName = "";
    if (oldParagraph.indent !== newParagraph.indent) {
        if (newParagraph.indent) {
            newClassName = INDENT_ADDED;
        }
        else {
            oldClassName = INDENT_REMOVED;
        }
    }
    let oldExtraClassName = "";
    let newExtraClassName = "";
    if ((newParagraph.singleSpace || newParagraph.nextElementIsInline) !== (oldParagraph.singleSpace || oldParagraph.nextElementIsInline)) {
        if (newParagraph.singleSpace) {
            oldExtraClassName = DOUBLE_SPACE_REMOVED;
        }
        else {
            newExtraClassName = DOUBLE_SPACE_ADDED;
        }
    }
    const oldParagraphJsx = getParagraphElement(oldParagraph, oldContent, oldClassName, oldExtraClassName);
    const newParagraphJsx = getParagraphElement(newParagraph, newContent, newClassName, newExtraClassName);
    return [oldParagraphJsx, newParagraphJsx];
}
function handleTwoWords(oldWord, newWord) {
    let [oldContent, newContent] = getTextDiff(oldWord.text, newWord.text);
    if (oldWord.bold !== newWord.bold || oldWord.italics !== newWord.italics) {
        oldContent = (React.createElement("span", { className: WORD_STYLE_REMOVED }, oldContent));
        newContent = (React.createElement("span", { className: WORD_STYLE_ADDED }, newContent));
    }
    return [getWordElement(oldWord, oldContent), getWordElement(newWord, newContent)];
}
function getParagraphElement(paragraph, content, mainClassName, extraClassName) {
    return (paragraph.singleSpace || paragraph.nextElementIsInline) ?
        React.createElement("p", { id: paragraph.indent ? Paragraph.INDENT_ATTRIBUTE : "", className: mainClassName }, content) :
        React.createElement(React.Fragment, null,
            React.createElement("p", { id: paragraph.indent ? Paragraph.INDENT_ATTRIBUTE : "", className: mainClassName }, content),
            React.createElement("p", { className: extraClassName }));
}
function getFootnoteElement(footnote, content, mainClassName = "", extraClassName = "", extraExtraClassName = "") {
    switch (footnote.footnoteType) {
        case FootnoteType.Inline:
            return React.createElement("blockquote", { id: Footnote.FOOTNOTE_ATTRIBUTE, itemType: "0", className: mainClassName }, content);
        case FootnoteType.EndOfSingleSpaceParagraph:
            return React.createElement(React.Fragment, null,
                React.createElement("blockquote", { id: Footnote.FOOTNOTE_ATTRIBUTE, itemType: "1", className: mainClassName }, content),
                React.createElement("p", { className: extraClassName }));
        case FootnoteType.EndOfDoubleSpaceParagraph:
            return React.createElement(React.Fragment, null,
                React.createElement("blockquote", { id: Footnote.FOOTNOTE_ATTRIBUTE, itemType: "1", className: mainClassName }, content),
                React.createElement("p", { className: extraClassName }),
                React.createElement("p", { className: extraExtraClassName }));
    }
}
function getWordElement(word, content) {
    if (word.bold)
        content = (React.createElement("strong", null, content));
    if (word.italics)
        content = (React.createElement("i", null, content));
    return content;
}
function getTitleElement(content, title) {
    if (title instanceof Part) {
        return (React.createElement("h1", null, content));
    }
    else if (title instanceof Chapter) {
        return (React.createElement("h2", null, content));
    }
    else if (title instanceof Section) {
        return (React.createElement("h3", null, content));
    }
    else {
        return (React.createElement("h4", null, content));
    }
}
