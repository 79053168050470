import { useEffect, useState } from "react";
import { UserManager } from "bookwebsite-usermanager"; 
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { GetAllBookMetadataResponse } from "bookapiinterface";
import { BookEditDisplay } from "./BookDisplay.tsx";

let initialBookMetadataResponse: GetAllBookMetadataResponse = {
  metadata: []
};

function App() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code");
  const [loggedIn, setLoggedIn] = useState(false);
  const [bookMetadataResponse, setBookMetadataResponse] = useState(initialBookMetadataResponse);
  const userManager = new UserManager(window.location.origin);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  useEffect(() => {
    if (loggedIn) {
      userManager.getAllBookMetadata().then((response) => {
        setBookMetadataResponse(response);
      });
    }
  }, [loggedIn]);

  useEffect(() => {
    if (userManager.isLoggedIn()) {
      setLoggedIn(true);
      return;
    }

    if (code === null) {
      window.location.replace(userManager.getLoginUrl());
      return;
    }

    userManager.loginUser(code).then(() => {
      console.log("Finished sign in request, logged in is: " + userManager.isLoggedIn());
      if (!userManager.isLoggedIn()) {
        window.location.replace(userManager.getLoginUrl());
        return;
      }
      setLoggedIn(true);
    });
  }, []);

  if (!loggedIn) {
    return (
        <div className="flex items-center justify-center h-screen w-screen"><Spinner animation="border" role="status" className="mx-auto my-auto">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        </div>
    );
  } else {
    return (
      <div>
      <div>
        Welcome {userManager.getName()}!
      </div>
      <BookEditDisplay userManager={userManager} bookMetadatas={bookMetadataResponse.metadata} setBookMetadataResponse={setBookMetadataResponse}/>
      </div>
    )
  }
}

export default App;
