import { BookPartDiff } from "./BookPartDiff.js";
import { FibonacciHeap } from '@tyriar/fibonacci-heap';
import { levenshteinEditDistance } from "levenshtein-edit-distance";
;
function incrementOld(state) {
    return {
        oldBookIndex: state.oldBookIndex + 1,
        newBookIndex: state.newBookIndex
    };
}
function incrementNew(state) {
    return {
        oldBookIndex: state.oldBookIndex,
        newBookIndex: state.newBookIndex + 1
    };
}
function mapKeyFromState(state) {
    return state.oldBookIndex.toString() + "," + state.newBookIndex.toString();
}
export function calculateMapping(oldBookParts, newBookParts) {
    const oldBookPartStrings = oldBookParts.map((part) => part.toString());
    const newBookPartStrings = newBookParts.map((part) => part.toString());
    const stateMap = new Map();
    const minHeap = new FibonacciHeap();
    function generateNextSteps(nextStep) {
        const nextSteps = [];
        const state = nextStep.state;
        if (state.oldBookIndex >= oldBookParts.length && state.newBookIndex >= newBookParts.length) {
            return [];
        }
        if (state.oldBookIndex < oldBookParts.length) {
            nextSteps.push({
                state: incrementOld(state),
                prevState: state,
            });
        }
        if (state.newBookIndex < newBookParts.length) {
            nextSteps.push({
                state: incrementNew(state),
                prevState: state,
            });
        }
        if (state.oldBookIndex < oldBookParts.length && state.newBookIndex < newBookParts.length) {
            nextSteps.push({
                state: incrementNew(incrementOld(state)),
                prevState: state,
            });
        }
        return nextSteps;
    }
    function calculateDistance(startState, nextState) {
        function hasOldIndexDiff() {
            return startState.oldBookIndex < nextState.oldBookIndex;
        }
        function hasNewIndexDiff() {
            return startState.newBookIndex < nextState.newBookIndex;
        }
        if (hasOldIndexDiff() && hasNewIndexDiff()) {
            return levenshteinEditDistance(oldBookPartStrings[startState.oldBookIndex], newBookPartStrings[startState.newBookIndex]) ?? Infinity;
        }
        else if (hasOldIndexDiff()) {
            return oldBookPartStrings[startState.oldBookIndex].length;
        }
        else if (hasNewIndexDiff()) {
            return newBookPartStrings[startState.newBookIndex].length;
        }
        return Infinity;
    }
    const firstStep = {
        state: {
            oldBookIndex: 0,
            newBookIndex: 0
        },
        prevState: null
    };
    let iterations = 0;
    let totalLatency = Date.now();
    let totalCalcLatency = 0;
    minHeap.insert(0, firstStep);
    while (!minHeap.isEmpty()) {
        const minValue = minHeap.extractMinimum();
        const minState = minValue?.value;
        const totalDistance = minValue?.key;
        if (minState === undefined)
            throw Error("State is undefined");
        if (totalDistance === undefined)
            throw Error("Total distance is undefined");
        const key = mapKeyFromState(minState.state);
        if (stateMap.has(key))
            continue;
        stateMap.set(key, minState);
        const nextSteps = generateNextSteps(minState);
        // At end and found shortest path
        if (nextSteps.length === 0)
            break;
        for (const nextStep of nextSteps) {
            const nextStepKey = mapKeyFromState(nextStep.state);
            if (stateMap.has(nextStepKey))
                continue;
            const now = Date.now();
            const nextDistance = totalDistance + calculateDistance(minState.state, nextStep.state);
            totalCalcLatency += (Date.now() - now);
            minHeap.insert(nextDistance, nextStep);
        }
    }
    iterations++;
    console.log("Iterations: " + iterations);
    console.log("Total latency: " + (Date.now() - totalLatency));
    console.log("Total calc latency: " + totalCalcLatency);
    const finalStepKey = mapKeyFromState({ oldBookIndex: oldBookParts.length, newBookIndex: newBookParts.length });
    const finalStep = stateMap.get(finalStepKey);
    if (finalStep === undefined)
        throw Error("Final state is undefined");
    const mapping = [];
    let currentStep = finalStep;
    while (currentStep.prevState !== null) {
        const currentState = currentStep.state;
        const prevState = currentStep.prevState;
        const oldBookPart = (currentState.oldBookIndex === prevState.oldBookIndex) ? null : oldBookParts[prevState.oldBookIndex];
        const newBookPart = (currentState.newBookIndex === prevState.newBookIndex) ? null : newBookParts[prevState.newBookIndex];
        mapping.unshift(new BookPartDiff(oldBookPart, newBookPart));
        const previousStep = stateMap.get(mapKeyFromState(prevState));
        if (previousStep === undefined)
            throw Error("Previous step is undefined");
        currentStep = previousStep;
    }
    return mapping;
}
