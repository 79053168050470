import { Wrapper } from "./Wrapper.js";
import { Words } from "./Words.js";
import { Subsection } from "./titles/Subsection.js";
import { Section } from "./titles/Section.js";
import { Part } from "./titles/Part.js";
import { Chapter } from "./titles/Chapter.js";
import { Paragraph } from "./containers/Paragraph.js";
import { BookPartContainer } from "./containers/BookPartContainer.js";
import { Footnote, FootnoteType } from "./containers/Footnote.js";
import { DiffChange, applyDiffChange } from "./diffs/LogChanges.js";
import { Quote } from "./containers/Quote.js";
import { TableOfContents } from "./tableOfContents/TableOfContents.js";
import { BookPartTitle } from "./titles/BookPartTitle.js";
import { parse, HTMLElement } from "node-html-parser";
import { decode } from '@node-projects/lean-he-esm/lib/methods/decode.js';
import { encode } from '@node-projects/lean-he-esm/lib/methods/encode.js';
import { FrontMatter } from "./FrontMatter.js";
import { MainMatter } from "./MainMatter.js";
import { StoredDiff } from "./diffs/StoredDiff.js";
export { DiffChange, applyDiffChange, StoredDiff };
export { Wrapper, Words, Subsection, Section, Part, Chapter, Paragraph, Footnote, Quote, BookPartTitle, TableOfContents, FrontMatter, MainMatter, FootnoteType, BookPartContainer };
export function fromHtmlString(htmlString) {
    const root = parse(htmlString);
    return new Wrapper(convertNodeList(root.childNodes));
}
export function formatTextForLatex(text) {
    text = text.replaceAll("\\", "\\textbackslash ");
    text = text.replaceAll("{", "\\{");
    text = text.replaceAll("}", "\\}");
    const greekRegex = new RegExp("[Ͱ-Ͽἀ-῾]+", "g");
    text = text.replace(greekRegex, (match) => `\\begin{greek}${match}\\end{greek}`);
    text = text.replaceAll(" ", "\\thinspace ");
    text = text.replaceAll("&", "\\&");
    text = text.replaceAll("$", "\\$");
    text = text.replaceAll("%", "\\%");
    return text;
}
export function formatTextForString(text) {
    return encode(text);
}
export function decodeTextFromString(text) {
    return decode(text);
}
const contentList = [new FrontMatter(), new MainMatter(), new Words("", false, false), new Subsection(""), new Section(""), new Part(""), new Chapter(""), new Paragraph([]),
    new Footnote([]), new Quote([])];
export function convertNode(node) {
    // Wrapper wraps the content in a div, catch this element here
    if (node instanceof HTMLElement && node.rawTagName === "div")
        return convertNodeList(node.childNodes);
    for (const content of contentList) {
        if (content.isHTML(node))
            return content.fromHTML(node);
    }
    console.error(node);
    throw new Error("Node is not of any content.");
}
export function convertNodeList(nodes) {
    const bookParts = [];
    for (const node of nodes) {
        bookParts.push(...convertNode(node));
    }
    return bookParts;
}
