import { Wrapper } from "bookdata";
import React from "react";
import { getDiff } from "./getDiff.js";
import { BOOK_PART_ADDED, BOOK_PART_DELETED, NEW_BOOK_PART, OLD_BOOK_PART } from "./Constants.js";
export class BookPartDiff {
    // If old part is null, then new part is created
    // If new part is null, then old part is destroyed
    // If neither is null, then part is modified
    constructor(oldPart, newPart) {
        this.oldPart = oldPart;
        this.newPart = newPart;
    }
    partsAreSame() {
        if (this.oldPart !== null && this.newPart !== null) {
            return this.oldPart.toString() === this.newPart.toString();
        }
        return false;
    }
    diffElementWrapper(oldElement, newElemet) {
        return (
        // Horizontal ordering
        React.createElement("div", { className: "book-part-diff-wrapper" },
            React.createElement("div", { className: OLD_BOOK_PART }, oldElement),
            React.createElement("div", { className: NEW_BOOK_PART }, newElemet)));
    }
    getJsxPair() {
        if (this.newPart === null && this.oldPart === null) {
            throw new Error("Book part diff contains two nulls");
        }
        else if (this.newPart === null) {
            return [
                (React.createElement("span", { className: BOOK_PART_DELETED }, this.oldPart?.toJSX())),
                React.createElement(React.Fragment, null)
            ];
        }
        else if (this.oldPart === null) {
            return [
                React.createElement(React.Fragment, null),
                (React.createElement("span", { className: BOOK_PART_ADDED }, this.newPart?.toJSX()))
            ];
        }
        else {
            return getDiff(this.oldPart, this.newPart);
        }
    }
    toJSX() {
        if (this.newPart === null && this.oldPart === null)
            return BookPartDiff.getMoreElements;
        const [oldContent, newContent] = this.getJsxPair();
        return this.diffElementWrapper(oldContent, newContent);
    }
    toJSON() {
        const returnJson = {};
        if (this.oldPart !== null) {
            returnJson["oldPart"] = new Wrapper([this.oldPart]).toJSON();
        }
        if (this.newPart !== null) {
            returnJson["newPart"] = new Wrapper([this.newPart]).toJSON();
        }
        return returnJson;
    }
    static fromJSON(json) {
        let oldPart = null;
        let newPart = null;
        if (json.oldPart !== undefined) {
            oldPart = json.oldPart.getChildren()[0];
        }
        if (json.newPart !== undefined) {
            newPart = json.newPart.getChildren()[0];
        }
        return new BookPartDiff(oldPart, newPart);
    }
}
BookPartDiff.getMoreElements = React.createElement(React.Fragment, null);
