import { Words } from "../Words.js";
import { BookPartContainer } from "../containers/BookPartContainer.js";
import { Footnote, FootnoteType } from "../containers/Footnote.js";
import { Paragraph } from "../containers/Paragraph.js";
export function generateBottomFootnoteBook(children) {
    const [newParagraph, footnotes, _] = replaceFootnote(new Paragraph(children), 1);
    return [newParagraph.getChildren(), footnotes];
}
function replaceFootnote(parent, index) {
    const newChildren = [];
    const footnotes = [];
    const children = parent.getChildren();
    for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child instanceof Footnote) {
            const bottomHref = "footnote-b-" + index.toString();
            const mainHref = "footnote-m-" + index.toString();
            const footnoteIndicator = new Words(index.toString(), false, false, { superscript: true, hyperlink: true, href: "#" + bottomHref, id: mainHref });
            // Add footnote indicator to last paragraph
            const lastChild = newChildren.pop();
            if (!(lastChild instanceof Paragraph))
                throw new Error("Footnote must come after paragraph.");
            // If footnote is inline, add next paragraph to footnote
            let singleSpace = lastChild.singleSpace;
            let extraElements = [];
            if (child.footnoteType === FootnoteType.Inline) {
                const nextElement = children[i + 1];
                if (nextElement instanceof Paragraph) {
                    extraElements = nextElement.getChildren();
                    singleSpace = nextElement.singleSpace;
                    i++;
                }
                else {
                    console.error(nextElement);
                }
            }
            const newParagraphChildren = [...lastChild.getChildren(), footnoteIndicator, new Words(" "), ...extraElements];
            const newParagraph = new Paragraph(newParagraphChildren, singleSpace, lastChild.indent, lastChild.nextElementIsInline);
            // Add indicator in front of footnote
            let newFootnote = addWordToContainer(child, new Words(" "));
            newFootnote = addWordToContainer(newFootnote, new Words(index.toString(), false, false, { superscript: false, hyperlink: true, href: "#" + mainHref, id: bottomHref }));
            if (!(newFootnote instanceof Footnote))
                throw new Error("Add word to container did not return footnote.");
            footnotes.push(newFootnote);
            newChildren.push(newParagraph);
            index++;
        }
        else if (child instanceof BookPartContainer) {
            const [newChild, footnote, newIndex] = replaceFootnote(child, index);
            newChildren.push(newChild);
            footnotes.push(...footnote);
            index = newIndex;
        }
        else {
            newChildren.push(child);
        }
    }
    const copy = parent.copy();
    copy.setChildren(newChildren);
    return [copy, footnotes, index];
}
function addWordToContainer(container, word) {
    if (container instanceof Paragraph) {
        const newChildren = [word, ...container.getChildren()];
        const copy = container.copy();
        copy.setChildren(newChildren);
        return copy;
    }
    else {
        const firstElement = container.getChildren()[0];
        if (!(firstElement instanceof BookPartContainer))
            throw new Error("Quote or footnote does not have containers for children");
        const copy = container.copy();
        copy.setChildren([addWordToContainer(firstElement, word), ...container.getChildren().slice(1)]);
        return copy;
    }
}
