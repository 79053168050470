import { fromHtmlString } from "../index.js";
import { diff_match_patch } from "diff-match-patch";
export function applyDiffChange(diffChange, oldSection) {
    const patch = DiffChange.diff.patch_fromText(diffChange.diffText);
    const [newSection, _] = DiffChange.diff.patch_apply(patch, oldSection.toString());
    return fromHtmlString(newSection);
}
export class DiffChange {
    constructor(startId, endId, diffText) {
        this.startId = startId;
        this.endId = endId;
        this.diffText = diffText;
    }
    static fromBooks(startId, originalBook, newBook) {
        const diff = DiffChange.diff.patch_make(originalBook.toString(), newBook.toString());
        const diffText = DiffChange.diff.patch_toText(diff);
        return new DiffChange(startId, startId + originalBook.size() - 1, diffText);
    }
    toJSON() {
        return {
            startId: this.startId,
            endId: this.endId,
            diffText: this.diffText,
            type: "diffChange"
        };
    }
    static fromJSON(json) {
        return new DiffChange(json.startId, json.endId, json.diffText);
    }
}
DiffChange.diff = new diff_match_patch();
