import React from "react";
import { BookPartTitle } from "./BookPartTitle.js";
import { HTMLElement } from "node-html-parser";
import { formatTextForString, decodeTextFromString } from "../index.js";
export class Part extends BookPartTitle {
    constructor() {
        super(...arguments);
        this.latexKeyword = "part";
    }
    toLatex() {
        return super.toLatex() + "\n\\setcounter{chapter}{0}";
    }
    copy() {
        return new Part(this.title);
    }
    getLevel() {
        return 1;
    }
    toJSX() {
        return React.createElement("h1", null, this.title);
    }
    toString() {
        return `<h1>${formatTextForString(this.title)}</h1>`;
    }
    fromHTML(node) {
        return [new Part(decodeTextFromString(node.innerText))];
    }
    isHTML(node) {
        if (!(node instanceof HTMLElement))
            return false;
        return node.rawTagName === "h1";
    }
}
