import { Words } from "../Words.js";
import { Paragraph } from "../containers/Paragraph.js";
export function homeOrphanWords(children) {
    const newChildren = [];
    let orphanedWords = [];
    for (const child of children) {
        if (child instanceof Words) {
            orphanedWords.push(child);
        }
        else {
            if (orphanedWords.length > 0) {
                newChildren.push(new Paragraph(orphanedWords));
                orphanedWords = [];
            }
            newChildren.push(child);
        }
    }
    if (orphanedWords.length > 0) {
        newChildren.push(new Paragraph(orphanedWords));
        orphanedWords = [];
    }
    return newChildren;
}
