import React from "react";
import { BookPartTitle } from "./BookPartTitle.js";
import { HTMLElement } from "node-html-parser";
import { formatTextForString, decodeTextFromString } from "../index.js";
export class Subsection extends BookPartTitle {
    constructor() {
        super(...arguments);
        this.latexKeyword = "subsection";
    }
    copy() {
        return new Subsection(this.title);
    }
    getLevel() {
        return 4;
    }
    toJSX() {
        return React.createElement("h4", null, this.title);
    }
    toString() {
        return `<h4>${formatTextForString(this.title)}</h4>`;
    }
    fromHTML(node) {
        return [new Subsection(decodeTextFromString(node.innerText))];
    }
    isHTML(node) {
        if (!(node instanceof HTMLElement))
            return false;
        return node.rawTagName === "h4";
    }
}
