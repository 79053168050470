import { BookPartDiff } from "./BookPartDiff.js";
import React from "react";
import { BOOK_DIFF } from "./Constants.js";
export class BookDiff {
    constructor(bookDiffs) {
        this.bookDiffs = bookDiffs;
    }
    toJSX(config = null) {
        const content = [];
        for (const bookDiff of this.getBookDiffsToDisplay(config)) {
            content.push(bookDiff.toJSX());
        }
        return (React.createElement("div", { className: BOOK_DIFF }, content));
    }
    getBookDiffsToDisplay(config = null) {
        if (config === null)
            return this.bookDiffs;
        const bookDiffsToDisplay = [];
        for (const bookDiff of this.bookDiffs) {
            if (this.displayIndex(bookDiffsToDisplay.length, config))
                bookDiffsToDisplay.push(bookDiff);
            if (this.displayMore(bookDiffsToDisplay.length, config))
                bookDiffsToDisplay.push(new BookPartDiff(null, null));
        }
        return bookDiffsToDisplay;
    }
    displayIndex(index, config) {
        for (let i = Math.max(0, index - config.displayContext); i <= Math.min(this.bookDiffs.length - 1, index + config.displayContext); i++) {
            if (!this.bookDiffs[i].partsAreSame())
                return true;
        }
        return false;
    }
    displayMore(index, config) {
        const plusIndex = index + config.displayContext + 1;
        if (plusIndex < this.bookDiffs.length && !this.bookDiffs[plusIndex].partsAreSame()) {
            return true;
        }
        const minusIndex = index - config.displayContext - 1;
        if (minusIndex >= 0 && !this.bookDiffs[minusIndex].partsAreSame()) {
            return true;
        }
        return false;
    }
    getBookDiffs() {
        return this.bookDiffs;
    }
    toJSON() {
        return {
            "bookDiffs": this.bookDiffs.map((bookDiff) => bookDiff.toJSON()),
            "type": "bookDiff"
        };
    }
    static fromJSON(json) {
        const bookDiffs = [];
        for (const bookDiff of json?.bookDiffs ?? []) {
            bookDiffs.push(BookPartDiff.fromJSON(bookDiff));
        }
        return new BookDiff(bookDiffs);
    }
}
