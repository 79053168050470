export const BOOK_DIFF = "book-diff";
export const OLD_BOOK_PART = "old-book-part";
export const NEW_BOOK_PART = "new-book-part";
export const BOOK_PART_DELETED = "book-part-deleted";
export const BOOK_PART_ADDED = "book-part-added";
export const WORD_DIFF_ADDED = "word-diff-added";
export const WORD_DIFF_REMOVED = "word-diff-removed";
export const INDENT_ADDED = "indent-added";
export const INDENT_REMOVED = "indent-removed";
export const DOUBLE_SPACE_ADDED = "double-space-added";
export const DOUBLE_SPACE_REMOVED = "double-space-removed";
export const FULL_BORDER_REMOVED = "full-border-removed";
export const FULL_BORDER_ADDED = "full-border-added";
export const WORD_STYLE_ADDED = "word-style-added";
export const WORD_STYLE_REMOVED = "word-style-removed";
