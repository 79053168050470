import React from "react";
import { HTMLElement } from "node-html-parser";
export class FrontMatter {
    copy() {
        return new FrontMatter();
    }
    toLatex() {
        return "\\frontmatter\n";
    }
    toJSX() {
        return (React.createElement("h1", null, "FrontMatter"));
    }
    toString() {
        return "<h1>FrontMatter</h1>";
    }
    fromHTML(node) {
        return [new FrontMatter()];
    }
    isHTML(node) {
        if (!(node instanceof HTMLElement))
            return false;
        if (!(node.rawTagName === "h1"))
            return false;
        if (!(node.innerText === "FrontMatter"))
            return false;
        return true;
    }
    clean() {
        return [this.copy()];
    }
}
